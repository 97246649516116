@use '@angular/material' as mat;
@import '@angular/material/theming';
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

$fa-font-path: "~font-awesome/fonts/";
@import '~font-awesome/css/font-awesome.css';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mat-deva: (
  50: #e5e2ef,
  100: #cbc6df,
  200: #b1a9d0,
  300: #978dc0,
  400: #70d83b,
  500: #70659f,
  600: #645a8d,
  700: #70d83b,
  800: #4b436a,
  900: #322d46,
  A100: #978dc0,
  A200: #70d83b,
  A400: #4b436a,
  A700: #322d46,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
); //#7d71b1;
$candy-app-primary: mat.define-palette($mat-deva, A200, A100, A400);
$candy-app-accent: mat.define-palette($mat-deva, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-dark-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);

