body {
  margin: 0px;
  background-color: #303030;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.full-width-container {
  display: flex;
  flex-direction: column;
}

.full-width-container > * {
  width: 100%;
}

half-width-container{
  display: inline-flex;
  flex-direction: column;
}

.half-width-container > * {
  width: 50%;
}

.mat-dialog-container {
  max-width: none !important;
}

.snack-error {
  border: 1px red solid;
  color: red;
  width: 80%;
}

.snack-confirm {
  border: 1px green solid;
  color: green;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.next-content-column {
  padding-left: 10px;
}

.editorText{
  color: black;
  background-color: white;
}

.spinner{
  background-color: rgba(100, 100, 100, 0.5);
  height:100%;
  width:100%;
  position:fixed;
  left:0;
  top:0;
  z-index:100 !important;
}

.spinner-content {
  width: 300px;
  height: 250px;
  position:absolute;
  top:50%;
  left:50%;
  margin-left:-100px;
  margin-top:-200px;
}


.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 1px -2px rgba(12, 12, 12, 0.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  border: 1px solid #fff;
}


div.mce-edit-area {
  background: #FFF;
  filter: none;
  overflow: scroll;
  height: 400px;
}

.mat-button-toggle-checked {
  background-color: #70d83b !important;
}

